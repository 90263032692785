import doubleseven from '../img/jamesbond.png';
import './Home.css';
import HomeAnimate from '../animation/Home';
import samGun from '../img/jamesbond.png'

function Home () {
    
    return (
       <div>
           <div class="topPage" style= {{backgroundImage: `url(${samGun})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            maxWidth: '100%'}}>
             <HomeAnimate/>
             </div>
             
       </div>
        
    );
}
export default Home;