import { Container, Nav, Navbar, NavbarBrand, NavLink } from "react-bootstrap";
import logos from '../img/sdFinal.png';

function NavigationBar () {
    return (
       <>
       <Navbar fixed bg="dark" variant="dark">
           <Container>
               <NavbarBrand href="/"><img src={logos} width="50" height="30"/></NavbarBrand>
           <Nav className="me-auto">
               <NavLink href="/about">About</NavLink>
               <NavLink href="/donate">Donate</NavLink>
           </Nav>
           </Container>
       </Navbar>
       </> 
    );
}
export default NavigationBar;