
import './App.css';
import NavigationBar from './components/NavigationBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Donate from './components/Donate';

function App() {
  return (
  
    <div className="App">
      <header className="App-header">
        <Router>
          <NavigationBar/>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/donate" element={<Donate/>}/>
            </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
