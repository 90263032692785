import { CDBFooter, CDBBox, CDBBtn, CDBIcon, CDBContainer } from 'cdbreact';
import logos from '../img/sdFinal.png';
import { HiOutlineMailOpen } from "react-icons/hi";

function Footer ()  {
    return (
<CDBFooter className="shadow">
      <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className="mx-auto py-4 flex-wrap"
        style={{ width: '80%' }}
      >
        <CDBBox display="flex" alignItems="center">
          <a href="/" className="d-flex align-items-center p-0 text-dark">
            <img
              alt="logo"
              src={logos}
              width="30px"
            />
            
          </a>
        </CDBBox>
        <CDBBox>
          <small className="ml-2"><HiOutlineMailOpen/>: Dyerrate@gmail.com </small>
        </CDBBox>
        
        <CDBBox display="flex">
        <CDBBtn flat color="dark" className="p-2" href="https://www.linkedin.com/in/samuel-c-dyer/" >
            <CDBIcon fab icon="linkedin"/>
          </CDBBtn>
          <CDBBtn flat color="dark" className="mx-3 p-2" href="https://twitter.com/sirdyer">
            <CDBIcon fab icon="twitter" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2" href="https://www.instagram.com/sirdyer/" >
            <CDBIcon fab icon="instagram" />
          </CDBBtn>
        </CDBBox>
      </CDBBox>
    </CDBFooter>
    );
}
export default Footer;