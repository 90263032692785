import Footer from './Footer';
import sam from '../img/sambackgrounV2.png';
import brain from '../img/brain.png';
import './About.css';
import React, { useEffect} from 'react';
import { Card } from 'react-bootstrap';
import SideText from '../animation/AboutCreative';
import { motion } from "framer-motion";
import { useState } from "react";
import motivation from '../img/motivation.gif';
import { IoIosArrowDown } from "react-icons/io";
import {VscCompass,VscBriefcase,VscRepo} from "react-icons/vsc";
import { DiApple,DiJavascript1,DiReact,DiCss3,DiHtml5,DiSwift} from "react-icons/di";
import { SiChase,SiSplunk,SiSafari } from "react-icons/si";
import {GiBinoculars} from "react-icons/gi";
import {BsFillFileEarmarkPdfFill} from "react-icons/bs";
import Pdf from '../Documents/resume.pdf';


function About () {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenPro, setIsOpenPro] = useState(false);
    const [isOpenProj, setIsOpenProj] = useState(false);
    const [isOpenModel, setIsOpenModel] = useState(false);

    return (
        <div className="aboutBox">
            <div id='panel2' class='d-flex align-items-center'>
                <div>
                <Card 
                    className="text-center"
                    bg = {'dark'}
                    text = {'light'}
                    style = {{width: '30vw',boxShadow: '0px 10px 30px rgb(0,0,0,.6)'  }}
                    >
                        <Card.Header id = "panel2Head">Inspiration</Card.Header>
                    <Card.Body
                        id = "panel2Body">
                        
                        <Card.Text>
                            Throughout my journey there has been many mentors, role models, friends, and teachers that 
                            have guided me. The ones who have made greate change in this world and continue to do so, inspire me 
                            to be a positive change in someones life with the technology I am able to create.
                            </Card.Text>
                            
                            <Card.Text id = "bottomText">
                            "I choose a lazy person to do a hard job. Because a lazy person will find an easy way to do it. "
                            - Bill Gates
                        </Card.Text>
                    </Card.Body>
                </Card>
                </div>
                <div>
                    <img style={{boxShadow: '0px 10px 30px rgb(0,0,0,.6)'}} id='jobs' src={motivation}></img>
                </div>
            </div>

            <div id = 'topBox'>
            <SideText/>
            </div>

            <div 
                id ='journey' 
                class='d-flex align-items-center'
                style= {{backgroundImage: `url(${brain})`,
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center'}}>
                <div id = "journeyBox">
                    <h2 id="myJourney">The journey so far <VscCompass/> </h2>
                </div>
                <div id='arrowSeperate' class='d-flex'>
                    <IoIosArrowDown id='arrowSize'/>
                    <IoIosArrowDown id='arrowSize'/>
                    <IoIosArrowDown id='arrowSize'/>
                </div>
                <div>
                    <motion.div 
                        layout onClick={() => setIsOpen(!isOpen)} 
                        className="cards"
                        style={{borderRadius: "1.6rem", boxShadow: '0px 10px 30px rgb(0,0,0,.8)' }}>
                        <motion.h3 layout="position">The Knowledge <VscRepo/></motion.h3>
                        {!isOpen &&
                        <motion.p id='clickHere'>(click here)</motion.p>
}                       
                        {isOpen &&
                        <motion.div>
                            
                            <ul>
                                <dt>Universities</dt>
                                <li>University of Texas at Arlington</li>
                                <li>Tarleton State University</li>
                            </ul>
                            <ul>
                                <dt>Degrees</dt>
                                <li>Bachelors of Business, Information Systems</li>
                                <li>Maybe a masters... idk</li>
                            </ul>
                            
                            
                        </motion.div>
                        }
                    </motion.div>
                </div>
                <div id='arrowSeperate' class='d-flex'>
                    <IoIosArrowDown id='arrowSize'/>
                    <IoIosArrowDown id='arrowSize'/>
                    <IoIosArrowDown id='arrowSize'/>
                </div>
                <div>
                    <motion.div 
                        layout onClick={() => setIsOpenPro(!isOpenPro)} 
                        className="cards"
                        style={{borderRadius: "1.6rem", boxShadow: '0px 10px 30px rgb(0,0,0,.8)' }}>
                        <motion.h3 layout="position">The Professional <VscBriefcase/></motion.h3>
                        {!isOpenPro &&
                        <motion.p id='clickHere'>(click here)</motion.p>
}                       
                        {isOpenPro &&
                        <motion.div>
                            
                            <ul>
                                <dt>Employment</dt>
                                <li>Apple<DiApple/> - Retail / Software Engineer</li>
                                <li>JPMorgan Chase<SiChase/> - Software Engineer</li>
                            </ul>
                            <h3>Tech Stack</h3>
                            <h4><DiJavascript1/><DiCss3 id='moveLeft'/><DiReact id='moveLeft'/><DiHtml5 id='moveLeft'/><SiSplunk id='moveLeft'/><DiSwift id='moveLeft'/></h4>
                                
                        </motion.div>
                        }
                    </motion.div>
                </div>
                <div id='arrowSeperate' class='d-flex'>
                    <IoIosArrowDown id='arrowSize'/>
                    <IoIosArrowDown id='arrowSize'/>
                    <IoIosArrowDown id='arrowSize'/>
                </div>
                <div>
                    <motion.div 
                        layout onClick={() => setIsOpenProj(!isOpenProj)} 
                        className="cards"
                        style={{borderRadius: "1.6rem", boxShadow: '0px 10px 30px rgb(0,0,0,.8)' }}>
                        <motion.h3 layout="position">The Projects<VscRepo/></motion.h3>
                        {!isOpenProj &&
                        <motion.p id='clickHere'>(click here)</motion.p>
}                       
                        {isOpenProj &&
                        <motion.div>
                            
                            <h5>SirDyer Website</h5>
                            <h5>Upcoming App</h5>
                            
                            
                        </motion.div>
                        }
                    </motion.div>
                </div>
            </div>
            <div id='moreLinks'
            style={{ boxShadow: '0px 10px 30px rgb(0,0,0,.8)' }}>
                <div>
                    <h5><a href={Pdf} target='_blank' rel='noopener noreferrer'> View resume here <BsFillFileEarmarkPdfFill/> </a></h5>
                </div>
            </div>
            
            <div id ='otherWork' class='d-flex align-items-center' style= {{
                    width: '100vw',
                    height: '75vh',
                    backgroundImage: `url(${sam})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'contain',
                    backgroundPosition: 'bottom'}}>
                        <div id='blueSteel'>
                            <motion.div 
                                layout onClick={() => setIsOpenModel(!isOpenModel)}
                                style={{borderRadius: "1.6rem", boxShadow: '0px 10px 30px rgb(0,0,0,.9)' }}
                                whileHover={{boxShadow: '0px 10px 30px rgb(100,100,100,.9)',background: '#F3F3F3', scale: 1.25 }}>
                                    <motion.div>
                                        <motion.h4 id='modellHighlight'>The</motion.h4>
                                        <motion.h1 id='campbelModelTitle'>Campbell Agency</motion.h1>
                                        <motion.h4 id='modellHighlight'>Runway / Print</motion.h4>
                                    </motion.div>    
                            </motion.div>
                        </div>
                        <div id='buttonBox'>
                            <form action="https://www.thecampbellagency.com/portfolio/men-new-faces/men/1489287/sam-dyer">
                            <button   id='booking'>More Info<GiBinoculars/></button>
                            </form>
                        </div>
            </div>

            <Footer />
        </div>
        
    );
}
export default About; 