import * as React from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import samGun from '../img/jamesbond.png'
import './HomeB.css'
import Home from '../components/Home'


export default function HomeAnimate() {
    const alignCenter = {display: 'flex', aligntItems: 'center' }
    return (
        <div>
            <div />
            <Parallax pages={5}>
                <ParallaxLayer  style={{ ...alignCenter, justifyContent: 'flex-start' }}>
                    <div className='firstText'>
                        <p>Samuel Dyer &#123;&#125; </p>
                        <p>Portfolio</p>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer sticky={{start: 1, end:3}} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
                    <div className="leaderTag">
                        <h2>Leader</h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer sticky={{start: 2, end:3}} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
                    <div className="gentelmenTag">
                        <h2>Gentelman</h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={3} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
                    <div className="developerTag">
                        <h2>Developer</h2>
                    </div>
                </ParallaxLayer>
                <ParallaxLayer offset={4} style={{ ...alignCenter, justifyContent: 'flex-start' }}>
                    <div className="homeButton">
                        <h2>Find out more</h2>
                        <a href="/about">
                        <button role="button">About</button>
                        </a>
                        <a href="/donate">
                        <button role="button">Donate</button>
                        </a>
                    </div>
                </ParallaxLayer>
                

            </Parallax>
            
        </div>

    )
}