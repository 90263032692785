import Footer from './Footer';
import Slider from '../animation/DonateSlide';
import hand from '../img/handsV1.png';
import './Donate.css'
import { motion } from 'framer-motion';
import cardPic from '../img/donatePicV1.jpg';

function Donate () {

    return (
        <div className="over">
        <div style= {{
            width: '100%',
            height: '100vh',
            backgroundImage: `url(${hand})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'scroll',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            resize: 'both'}}>
                <motion.div id='topSlide'
                    initial={{ opacity: -1, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      default: {
                        duration: 5.0,
                        ease: [0, 0.71, 0.2, 1.01]
                      },
                      scale: {
                        type: "fade-in",
                        damping: 5,
                        stiffness: 100,
                        restDelta: 0.001
                      }
                    }}>
                    <motion.h2 id="givingBack">Giving Back To The Supporters</motion.h2>
                </motion.div>  
        </div>
        <div 
            class='d-flex'
            id='donateCard'>
            <div 
                className='donateCards'
                style={{borderRadius: '.85rem', width:'50vw',boxShadow: '0px 10px 30px rgb(0,0,0,.8)'}}>
                <p>I've created this page to show support for those chasing thier dreams, looking for support, or 
                    carving thier own path in life. To help dreamers become doers, because "good enough" isn't, and if 
                    you're still reading this. I challenge you to 'Be curious, not judgmental'. 
                </p>
                <p>Continue scrolling to see those I am supporting!</p>
                <img 
                    style={{borderRadius: '.85rem'}}
                    src={cardPic}/>
            </div>
        </div>
                <div id='slide'>
                    <Slider/>
                </div> 
                <Footer/>
        </div>
    );
}
export default Donate;