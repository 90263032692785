import { useRef } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue
} from "framer-motion";
import './DonateSlide.css';
import tiger from '../img/tigerTree.jpg';
import dan from '../img/yungbuk.jpg';
import jp from '../img/jp.jpeg';
import { RiCamera3Fill } from "react-icons/ri";
import { CgMusicSpeaker,CgDropInvert } from "react-icons/cg";

const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [1, 0], [-distance, distance]);
}

function ImageTwo() {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const y = useParallax(scrollYProgress, 230);
  
    return (
      <section>
        <div id="danBox" style={{boxShadow: '10px 13px 10px 1px rgba(0,0,0,1)' }} ref={ref}>
          <img className="image" src={dan}  />
          <div className="danHover">
            <p>YUNGBUCK is an upcoming DJ avalible for booking in DFW</p>
          </div>
        </div>
        <motion.h2 style={{ y }}><CgMusicSpeaker/></motion.h2>
        <button className="button-40" onClick={() => openInNewTab('https://soundcloud.com/heyoungbuck')} role="button">The Beats</button>
      </section>
    );
  }

  function ImageThree() {
    const ref1 = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref1 });
    const y = useParallax(scrollYProgress, 230);
  
    return (
      <section>
        <div id="jpBox" style={{boxShadow: '10px 13px 10px 1px rgba(0,0,0,1)' }} ref={ref1}>
          <img className="imageTwo" src={jp}  />
          <div className="jpHover">
            <p>Jp Petrou, is current in the process of recovering from a sick bike accident. </p>
          </div>
        </div>
        <motion.h2 style={{ y }}><CgDropInvert/></motion.h2>
        <button className="button-40" onClick={() => openInNewTab('https://www.gofundme.com/f/help-with-jps-bike-accident-expenses?utm_campaign=p_cp+share-sheet&utm_medium=copy_link_all&utm_source=customer')} role="button">Support</button>
      </section>
    );
  }

function ImageOne() {
  const ref2 = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref2 });
  const y = useParallax(scrollYProgress, 230);

  return (
    <section>
      <div id="tigerBox" style={{boxShadow: '10px 13px 10px 1px rgba(0,0,0,1)' }} ref={ref2}>
        <img className="imageThree" src={tiger}  />
        <div className="tigerHover">
            <p>Ram Jo Sharma, is an amazing widlife photagrapher. Click to see his portfolio. </p>
          </div>
      </div>
      <motion.h2 style={{ y }}><RiCamera3Fill/></motion.h2>
      <button className="button-40" role="button" onClick={() => openInNewTab('https://www.instagram.com/ram_jo_sharma/')}>Photos</button>
    </section>
  );
}

export default function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 1
  });

  return (
    <>

        <ImageOne/>
        <ImageTwo/>
        <ImageThree/>

    </>
  );
}